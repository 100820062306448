import React from 'react';
import { omit } from 'lodash';
import {
  ChatBubbleRounded,
  FavoriteRounded, VisibilityRounded,
} from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import { RetweetIcon } from 'elements/RetweetIcon';
import { MetricProps } from './types';

const metrics: { [key: string]: MetricProps } = {
  views: {
    icon: <VisibilityRounded fontSize="small" />,
    field: 'currentCombinedViews',
    deltaField: 'deltaCombinedViews',
    renderIfNull: false,
    label: 'views',
  },
  likes: {
    icon: <FavoriteRounded fontSize="small" />,
    field: 'currentLikes',
    deltaField: 'deltaLikes',
    renderIfNull: false,
    label: 'likes',
  },
  shares: {
    icon: <RetweetIcon fontSize="small" />,
    field: 'currentShares',
    deltaField: 'deltaShares',
    renderIfNull: false,
    label: 'shares',
  },
  comments: {
    icon: <ChatBubbleRounded fontSize="small" />,
    field: 'currentComments',
    deltaField: 'deltaComments',
    renderIfNull: false,
    label: 'comments',
  },
  impressions: {
    icon: <BarChartIcon fontSize="small" />,
    field: 'currentImpressions',
    deltaField: 'deltaImpressions',
    renderIfNull: false,
    label: 'impressions',
  },
};

export const getAllowedMetrics = (network: number, isPostCard: boolean) => {
  if (isPostCard) {
    const m = omit(metrics, ['impressions']);
    return Object.values(m);
  }
  return Object.values(metrics);
};
