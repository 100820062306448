import { Avatar } from '@mui/material';
import { Add } from '@mui/icons-material';
import Link from 'next/link';
import { MobileTooltip } from 'components/MobileTooltip';

const AVATAR_SIZE = 30;

interface Props {
  title: string;
  path: string;
}

export const AddCreditAvatar = ({ title, path }: Props) => (
  <Link href={`${path}?add=1`}>
    <MobileTooltip
      title={title}
      key="add"
      arrow
    >
      <Avatar
        className="mr-1 border-2 cursor-pointer bg-white border-black text-black hover:border-orange hover:text-orange"
        sx={{ width: AVATAR_SIZE, height: AVATAR_SIZE }}
        key="plus"
        variant="circular"
        alt="plus"
      >
        <Add />
      </Avatar>
    </MobileTooltip>
  </Link>
);
